const defaultSeo = {
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://bols.com',
    site_name: 'Bols - The world’s first cocktail brand',
  },
};

export default defaultSeo;
