import { ToastContainer } from 'react-toastify';

import { useIsMobile } from '~hooks';

export const Container = () => {
  const isMobile = useIsMobile();

  return (
    <ToastContainer
      position={isMobile ? 'bottom-center' : 'bottom-right'}
      closeButton={false}
      autoClose={4000}
      pauseOnFocusLoss
      hideProgressBar
      pauseOnHover
    />
  );
};
