import React, { ReactNode, useRef } from 'react';
import { StoryProvider } from '@storyofams/storyblok-toolkit';
import { LazyMotion } from 'framer-motion';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ThemeProvider } from 'styled-components';

import { ShopifyProvider } from '~context';
import { AgeConsentProvider } from '~components/AgeConsentProvider';
import { CountryCheckProvider } from '~components/CountryCheckProvider';

import theme from '~styles/theme';

const loadFeatures = () =>
  import('./motionFeatures').then((res) => res.default);

type ProviderProps = {
  children: ReactNode;
  /** defaults to empty object */
  pageProps?: any;
  /** defaults to 'en' as per the defaultLocale */
  locale?: string;
};

export const Providers = ({
  children,
  locale = 'en',
  pageProps = {},
}: ProviderProps) => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <ThemeProvider theme={theme}>
          <IntlProvider
            messages={pageProps.messages}
            defaultLocale="en"
            locale={locale}
          >
            <StoryProvider
              token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
              resolveRelations={[
                'contact_form_dynamic.form',
                'event_form_dynamic.form',
                'course_form_dynamic.form',
                'elearning_form_dynamic.form',
                'products_dynamic.shopify_products',
                'cocktails_dynamic.cocktails',
                'home.cocktails_list',
                'home.callToAction',
                'home.instagram',
                'Cocktail.related',
                'Cocktail.readyToDrink',
                'Cocktail.callToAction',
                'Cocktail.instagramSection',
                'dynamicPage.callToAction',
                'dynamicPage.instagramSection',
                'cta_dynamic.call_to_action',
              ]}
            >
              <ShopifyProvider>
                <LazyMotion features={loadFeatures}>
                  <AgeConsentProvider>
                    <CountryCheckProvider>{children}</CountryCheckProvider>
                  </AgeConsentProvider>
                </LazyMotion>
              </ShopifyProvider>
            </StoryProvider>
          </IntlProvider>
        </ThemeProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};
