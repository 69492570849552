import { ReactNode, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useShopify } from '~context';

import { Button } from '~components/common/Button';
import { Modal } from './common/Modal';

interface CountryCheckProviderProps {
  children: ReactNode;
}

const messages = defineMessages({
  countryCheckTitle:
    "It seems you're traveling in the magical world of cocktails.",
  countryCheckDescription:
    'Currently you are shopping in the {storeCountry} store, but you seem to be located in the {country}. Would you like to go shopping in the {country} store instead?',
  confirm: 'Yes, take me to the the {country} store',
  cancel: 'No, stay in the {storeCountry} store',
});

const REDIRECT_NL_TO_US_NAME = 'redirectNLtoUS';
const REDIRECT_NL_TO_US_VALUE = 'TRUE';

export const CountryCheckProvider = ({
  children,
}: CountryCheckProviderProps) => {
  const [shouldShow, setShouldShow] = useState(null);
  const router = useRouter();
  const intl = useIntl();

  const { country } = useShopify();
  const storeCountry = process.env.NEXT_PUBLIC_STORE_COUNTRY?.toUpperCase();

  const countryCheck = () => {
    const storeNLUserUS =
      storeCountry === 'NL' &&
      country === 'US' &&
      process.env.NEXT_PUBLIC_US_URL;
    const storeUSUserNL =
      storeCountry === 'US' &&
      country === 'NL' &&
      process.env.NEXT_PUBLIC_NL_URL;

    const shouldRedirect = Cookies.get(REDIRECT_NL_TO_US_NAME);
    if (shouldRedirect && shouldRedirect === REDIRECT_NL_TO_US_VALUE) {
      changeStoryCountry();
    } else {
      setShouldShow(storeNLUserUS || storeUSUserNL);
    }
  };

  useEffect(() => {
    countryCheck();
  }, [country]);

  const changeStoryCountry = () => {
    if (storeCountry === 'US' && country === 'NL') {
      router.push(
        `${process.env.NEXT_PUBLIC_NL_URL}${router.pathname}?country-switch=${country}`,
      );
    }
    if (storeCountry === 'NL' && country === 'US') {
      Cookies.set(REDIRECT_NL_TO_US_NAME, REDIRECT_NL_TO_US_VALUE);
      router.push(
        `${process.env.NEXT_PUBLIC_US_URL}${router.pathname}?country-switch=${country}`,
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={shouldShow !== null && shouldShow}
        close={() => setShouldShow(false)}
        title={intl.formatMessage(messages.countryCheckTitle)}
        description={intl.formatMessage(messages.countryCheckDescription, {
          storeCountry,
          country,
        })}
      >
        <Button onClick={changeStoryCountry} my={2}>
          <FormattedMessage {...messages.confirm} values={{ country }} />
        </Button>

        <Button variant="outline" onClick={() => setShouldShow(false)}>
          <FormattedMessage {...messages.cancel} values={{ storeCountry }} />
        </Button>
      </Modal>
      {children}
    </>
  );
};

export default CountryCheckProvider;
