import { useEffect, useRef } from 'react';
import { withPasswordProtect } from '@storyofams/next-password-protect';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'nprogress/nprogress.css';

import { UserAgent } from '~components';
import { seo } from '~config';
import { useDetectKeyboard } from '~hooks';
import { withB2B } from '~lib';
import { pageview, track } from '~/lib/gtag';
import { Providers } from '~components/common/Providers';
import { Container as ToastContainer } from '~components/common/Toast/Container';
import CSSreset from '~styles/CSSreset';

import 'react-toastify/dist/ReactToastify.min.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  import('~lib/sentry').then((m) => m.initSentry());
}

let qualifiedTimer;

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const progressTimer = useRef<any>(null);
  const isTrackingQualified = useRef(false);

  const { locale } = router;

  useDetectKeyboard();

  const clearQualifiedTrackers = () => {
    if (qualifiedTimer) {
      clearTimeout(qualifiedTimer);
    }

    document.removeEventListener('click', setQualified);
    document.removeEventListener('scroll', setQualified);
  };

  const setQualified = () => {
    if (isTrackingQualified.current) {
      return;
    }

    isTrackingQualified.current = true;

    clearQualifiedTrackers();

    track({
      event: 'tracking',
      userType: 'Qualified',
    });
  };

  useEffect(() => {
    qualifiedTimer = setTimeout(setQualified, 10000);
    document.addEventListener('click', setQualified, { passive: true });
    document.addEventListener('scroll', setQualified, { passive: true });

    return clearQualifiedTrackers;
  }, []);

  useEffect(() => {
    const startProgress = () => {
      if (!progressTimer.current) {
        progressTimer.current = setTimeout(NProgress.start, 120);
      }
    };

    const onComplete = (url) => {
      pageview(url);
      endProgress(url);
    };

    const endProgress = (err) => {
      if (progressTimer.current) {
        clearTimeout(progressTimer.current);
        progressTimer.current = null;

        if (err?.cancelled) {
          NProgress.set(0.0);
          NProgress.remove();
        } else {
          NProgress.done();
        }
      }
    };

    router.events.on('routeChangeStart', startProgress);
    router.events.on('routeChangeComplete', onComplete);
    router.events.on('routeChangeError', endProgress);

    return () => {
      router.events.off('routeChangeStart', startProgress);
      router.events.off('routeChangeComplete', onComplete);
      router.events.off('routeChangeError', endProgress);
    };
  }, [router.events]);

  if (process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b') {
    Component = withB2B(Component);
  }

  return (
    <Providers pageProps={pageProps} locale={locale}>
      <CSSreset />
      <DefaultSeo {...seo} />
      <UserAgent />
      <Component {...pageProps} />
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </Providers>
  );
};

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginComponentProps: {
        buttonBackgroundColor: '#010406',
        buttonColor: '#fff',
        logo: '/images/1575-emblem.png',
      },
    })
  : MyApp;
